import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

(rootElement?.hasChildNodes() ? hydrate : render)(
  <Router>
    <App />
  </Router>,
  rootElement
);

serviceWorker.unregister();
