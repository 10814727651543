export default {
  en: {
    CompanyMainTitle: 'Unique Data Products for every business goal',
    CompanyMainSubtitle:
      'We apply the latest techniques in <strong>Machine Learning</strong> and <strong>Data Science</strong> to solve real world problems that bring value to your business.',

    CompanyDataTitle: 'Data Nerds is not just a slogan',
    CompanyDataCard1:
      'We are computer scientists, engineers, mathematicians, economists, <strong>but above all we are Data Nerds.</strong>',
    CompanyDataCard2:
      'We have experience building and maintaining <strong>data systems that process 100s of TBs</strong> for large scale multinational corporations and technology startups.',
    CompanyDataCard3:
      'We have built systems that can process data in real-time, in the Cloud and that use the latest techniques in <strong>Machine Learning to drive business value.</strong>',

    CompanyTeamTitle: 'Meet the <br> Founding Team',
    CompanyTeamMember1Role: 'CEO',
    CompanyTeamMember2Role: 'CTO',
    CompanyTeamMember3Role: 'COO',
    CompanyTeamMember4Role: 'CRO',

    CompanyValuesTitle: 'Our values',
    CompanyValuesBuble1Title: 'We are data nerds',
    CompanyValuesBuble1Text: [
      'We share a passion for evolving and learning.',
      'We champion technology craftsmanship using best tools, practices, and methodologies for the job.',
      'We enjoy being challenged and working on new things.',
    ],
    // CompanyValuesBuble1Text: "We are passionate about technology. We are constantly trying new things, looking for ways to improve what doesn’t work perfectly and building upon what does.",
    // CompanyValuesBuble2Title: "We are an open team",
    // CompanyValuesBuble2Text: "We grow when you do. Mutt Data is a place to hone your skills, learn and help others learn too. We collaborate and openly request advice when we don’t know something.",
    CompanyValuesBuble2Title: 'We are open team players',
    CompanyValuesBuble2Text: [
      'We all have a key role to play, everybody wins when the team wins.',
      'We favour open, async, written communication.',
      'We build long-lasting productive relationships with our clients.',
    ],
    // CompanyValuesBuble3Title: "We embrace remote principles",
    // CompanyValuesBuble3Text: "We were remote, before it was the new normal. Async first, written over oral. We work to reach a goal and not just to comply with a schedule. We keep relevant people in the loop.",
    CompanyValuesBuble3Title: 'We take ownership',
    CompanyValuesBuble3Text: [
      "We strive to understand our client's needs and wants.",
      'We proactively solve issues removing bottlenecks and uncertainty.',
      'We hold ourselves accountable for maximizing client value.',
    ],
    // CompanyValuesBuble4Title: "We care for our clients",
    // CompanyValuesBuble4Text: "We understand the value of the project and how it fits into our client’s business goals, we prioritize and we deliver. We build long-lasting relationships. See for yourself.",
    CompanyValuesBuble4Title: 'We have a positive mind-set',
    CompanyValuesBuble4Text: [
      'We are optimistic and easy going: expect the best.',
      'We are humble and take failures as learning opportunities.',
      'We care for others and help them grow through candid and kind feedback.',
    ],

    CompanyContributeTitle: 'We contribute to the community',
    CompanyContributeText1:
      'We have over 10 years of experience building data systems using Big Data, Public Cloud and Machine Learning.',
    CompanyContributeTextBlue:
      'We believe in sharing the accumulated experiences and knowledge with the community.',
    CompanyContributeText2:
      'We regularly participate and host meetups and workshops focusing on Data Engineering, Data Infrastructure, Data Science, Machine Learning and ML Ops.<br><br>We are also very proud of sharing our knowledge:',

    CompanyContributeCardText:
      'We are organizers of the <strong>Argentina Big Data Meetup and Machine Learning Seminar</strong>, and we also teach in postgraduate programs from top universities in LATAM.',
    CompanyContributeBlog: 'Discover our blog',
    CompanyContributeRepository: 'Browse our repositories',
    CompanyContributeMeetup: 'See meetups & lessons',

    CompanyPrefooterTitle: 'Psst … <br> We’re <br> hiring!',
    CompanyPrefooterText:
      'We are looking for outstanding data and ML engineers, data scientists and engineering managers to help us develop state of the art solutions for fast-growing businesses around the world.',
    CompanyPrefooterButton: 'Apply now',
  },
};
