import React, { SVGAttributes } from 'react';

const TwitterIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="nonzero"
      {...props}
    >
      <path d="M12,0 C5.3735962,0 0,5.3735962 0,12 C0,18.6264038 5.3735962,24 12,24 C18.6264038,24 24,18.6264038 24,12 C24,5.3735962 18.6264038,0 12,0 Z M17.479065,9.35632322 C17.484375,9.47442628 17.4869385,9.59307862 17.4869385,9.71228025 C17.4869385,13.3518677 14.7165527,17.5488281 9.65020753,17.5490112 L9.65039062,17.5490112 L9.65020753,17.5490112 C8.09472656,17.5490112 6.64727784,17.0930786 5.42834475,16.3117676 C5.64385988,16.3372193 5.8632202,16.3498535 6.08532713,16.3498535 C7.37585447,16.3498535 8.56347656,15.909668 9.50628661,15.1708374 C8.30053711,15.1484985 7.28393555,14.3521729 6.93310547,13.2577515 C7.10101317,13.289978 7.27368164,13.3075561 7.45074464,13.3075561 C7.70214844,13.3075561 7.94567869,13.2736816 8.177124,13.2105103 C6.91680909,12.9581909 5.96740725,11.8443604 5.96740725,10.5106201 C5.96740725,10.498169 5.96740725,10.4869995 5.96777344,10.4754638 C6.33892823,10.6818237 6.76336669,10.8059693 7.21527098,10.8198853 C6.47570803,10.326416 5.989563,9.48284911 5.989563,8.52722166 C5.989563,8.02258303 6.12597656,7.54980469 6.36254883,7.14276122 C7.72082522,8.80938722 9.75073242,9.90545653 12.039917,10.0206299 C11.9926758,9.81884766 11.9683228,9.60864258 11.9683228,9.39257812 C11.9683228,7.87207031 13.2019043,6.63848878 14.7229614,6.63848878 C15.5152588,6.63848878 16.2308349,6.97338867 16.7334595,7.50878906 C17.3609619,7.38500977 17.9501953,7.15576172 18.4824829,6.84027098 C18.2764893,7.4831543 17.8399658,8.02258303 17.2712402,8.36370848 C17.8284302,8.29705809 18.359436,8.14929197 18.8529052,7.92993164 C18.484314,8.48236083 18.0168457,8.96759034 17.479065,9.35632322 Z"></path>
    </svg>
  );
};

export default TwitterIcon;
