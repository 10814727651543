export default {
  en: {
    HomeMain1Title: 'Boost your business with data',
    HomeMain1Subtitle:
      'Apply Big Data and Data Science to build automated systems that <strong>maximize your revenue.</strong>',
    HomeMain2Title: 'Optimize your operations with Machine Learning',
    HomeMain2Subtitle:
      'Sail through the Machine Learning journey with <strong>a team of experts</strong> that will help you all the way.',
    HomeMain3Title: 'Scale your systems with Big Data technologies',
    HomeMain3Subtitle:
      'Implement cost-efficient data architectures in the Cloud with flexible tools that <strong>easily adapt to your needs.</strong>',

    HomeMainButton: 'Contact us',

    HomeAboutCol1Title: `+ 1 decade`,
    HomeAboutCol1Text:
      'Implementing ML systems and<br/>Big Data infrastructures.',
    HomeAboutCol2Title: `+ 100 experts`,
    HomeAboutCol2Text: 'In Data Science and<br/>Data Engineering.',
    HomeAboutCol3Title: '+ 100 projects',
    HomeAboutCol3Text: 'Successfully deployed<br/>for global customers.',
    HomeAboutButton: 'Learn more about us',

    HomeDataPretitle: 'Our expertise',
    HomeDataTitle: 'We build modern Data Systems',
    HomeDataButton: 'Discover our solutions',
    HomeDataCard1Title: 'Big Data',
    HomeDataCard1Text:
      'Confidently turn insights into actions that increase revenue with modern and scalable data systems used by companies such as Google, Facebook and Amazon.',
    HomeDataCard2Title: 'Software Engineering',
    HomeDataCard2Text:
      'Build robust and high quality data systems using best practices for code versioning, refactoring, formatting, testing, CI/CD and monitoring.',
    HomeDataCard3Title: 'Cloud Computing',
    HomeDataCard3Text:
      'We excel in efficient use of cloud providers to lower costs and improve time to market.',
    HomeDataCard4Title: 'Machine Learning',
    HomeDataCard4Text:
      'From conducting exploratory data analysis to identify issues and business opportunities, to implementing, evolving and maintaining Machine Learning algorithms, our team is well-equipped to assist you every step of the way.',

    HomeTestimonialTextHabitat01:
      'We’ve worked with the team at Mutt Data for several months and they have become an extension of our team.',
    HomeTestimonialTextHabitat02:
      'From data exploration and data architecture to developing and operating Machine Learning systems, their team has the expertise and commitment to make any project a success.',
    HomeTestimonialSignatureHabitat: 'Mike Paszkiewicz',
    HomeTestimonialJobPositionHabitat: 'Chief Technology Officer',
    HomeTestimonialResultsNumberHabitat: '3x',
    HomeTestimonialResultsTextHabitat: 'Costs saving over building in-house',

    HomeTestimonialTextSirum01:
      'Mutt Data was an extension of our team, they worked closely with us understanding our specific business needs to advise us on the best possible solution considering all tradeoffs.',
    HomeTestimonialTextSirum02:
      ' Their experience building Cloud and data infrastructures and full stack ML systems allowed for a cost-effective and robust data system.',
    HomeTestimonialSignatureSirum: 'Adam Kircher',
    HomeTestimonialJobPositionSirum: 'Co-Founder',
    HomeTestimonialResultsNumberSirum: '3x',
    HomeTestimonialResultsTextSirum: 'Costs saving over building in-house',

    HomeTestimonialTextClassDojo01:
      'In Mutt we found a long-term partner. Their expertise in building Data Ops platforms, Apache Airflow, data warehouses, and best practices for developing data pipelines led us to a cost-effective, top-of-the-line data architecture.',
    HomeTestimonialTextClassDojo02:
      'They understood the vital role data privacy and anonymization played in our business improving upon our data strategies.',
    HomeTestimonialSignatureClassDojo: 'Dominick Bellizzi',
    HomeTestimonialJobPositionClassDojo: 'Chief Technology Officer',
    HomeTestimonialResultsNumberClassDojo: '3x',
    HomeTestimonialResultsTextClassDojo: 'Costs saving over building in-house',

    HomeTestimonialText01Ank:
      'In Mutt Data we found a partner with extensive experience building data systems from design to production. They worked seamlessly with both our technical and business teams.',
    HomeTestimonialText02Ank:
      'Their know-how on building modern data architectures on Amazon Web Services (AWS) was fundamental in developing a reconciliation system which has proven essential to efficiently scaling our operations.',
    HomeTestimonialSignatureAnk: 'Gustavo Arjones',
    HomeTestimonialJobPositionAnk: 'Chief Technology Officer',
    HomeTestimonialResultsNumberAnk: '3x',
    HomeTestimonialResultsTextAnk: 'Costs saving over building in-house',

    HomeTestimonialTextML01:
      'Mutt Data has proven to be an instrumental growth partner for Mercado Ads, offering guidance on the best ways to improve our Ad platform with high quality ML systems.',
    HomeTestimonialTextML02: "Mutt's team surpassed our expectations.",
    HomeTestimonialSignatureML: 'Leonardo Baccarelli',
    HomeTestimonialJobPositionML: 'Software Development Director',
    HomeTestimonialResultsNumberML: '3x',
    HomeTestimonialResultsTextML: 'Costs saving over building in-house',

    HomeTestimonialTextEtermax01:
      "As both advertisers and media providers, we're always seeking better ways to improve our reach and performance for our network partners' titles and our own games.",
    HomeTestimonialTextEtermax02:
      "Thanks to allies like Mutt Data, we've seen a significant optimization on the DSP we use that has driven growth in inventory results for our in-game advertising solutions.",
    HomeTestimonialSignatureEtermax: 'Juan Pablo Veiga',
    HomeTestimonialJobPositionEtermax: 'VP of Brand Gamification at Etermax.',

    HomeTestimonialLink: 'Read our Success Story',

    HomeAWSHeader: 'Amazon Web Services',
    HomeAWSSubtitle: 'Advanced Consulting Partner',
    HomeAWSText01:
      '<strong>Amazon Web Services (AWS)</strong> is the leading public cloud service provider in the world, offering virtual servers, storage, computing and networking services.',
    HomeAWSText02:
      'Mutt Data is proud to be an <strong>AWS Advanced Consulting Partner.</strong> We continually improve our know-how and train our teams on the latest cloud technologies. Our team follows rigorous industry standards and AWS best practices building cloud enabled systems for our clients. ',
    HomeAWSText03:
      'We have extensive expertise in Athena, EMR, Sagemaker, Textract, Rekognition, Glue, Redshift and Medical Comprehend among other tools applied for Data Engineering, Machine Learning, Computer Vision and Natural Language Processing.',

    HomeAstronomerHeader: 'Astronomer',
    HomeAstronomerSubtitle: 'Technological Partnership',
    HomeAstronomerText01:
      '<strong>Astronomer</strong> is a steward of the Apache Airflow project, at the center of the Airflow community and ecosystem, and the driving force behind releases and the Airflow Roadmap.',
    HomeAstronomerText02:
      'Astronomer offers the best Apache Airflow-based platform in the market. Having a solid foundation to build upon ensures that the data systems we create can achieve next-level quality, scalability, and stability.',
    HomeAstronomerText03:
      'With a focus on developer productivity, observability, and flexibility for any organization at any scale, Astronomer helps to quickly and easily deliver results with Airflow.',

    HomeKaszekHeader: 'Kaszek',
    HomeKaszekSubtitle: 'Business Partner',
    HomeKaszekText01:
      '<strong>Kaszek</strong> is a dynamic venture capital firm committed to empowering technology-based companies to reach their ultimate potential. With an experienced team of professionals, Kaszek provides comprehensive support to its portfolio companies in various stages of development, from seed to growth.',
    HomeKaszekText02:
      "We've partnered up with <strong>Kaszek</strong> to offer their portfolio companies strategic consulting to achieve robust and long-lasting data and AI-based solutions.",

    HomeProductMindsHeader: 'Product Minds',
    HomeProductMindsSubtitle: 'Business Partner',
    HomeProductMindsText01:
      '<strong>Product Minds</strong> is a Latin American reseller of Amplitude that offers vital consultancy services in Digital Strategy, Growth Marketing, and MarTech stack implementations.',
    HomeProductMindsText02:
      'Product Minds enables businesses to develop outstanding customer experiences at scale. With a team of certified solution architects and customer success managers, they assist companies in transforming their operations to become product-focused and data-centric.',
    HomeProductMindsLinkText: 'Explore our latest collaborative webinar',

    HomeH20Header: 'H20.ai',
    HomeH20Subtitle: 'Technological Partner',
    HomeH20Text01:
      '<strong>H20.ai</strong> is a top-tier AI cloud software company that delivers open-source and commercial AI and machine learning platforms. Their platforms enable businesses to build and deploy AI and ML models at scale to become data-driven.',
    HomeH20Text02:
      '<strong>H20.ai</strong> is committed to democratizing AI for everyone by enabling customers to rapidly make, operate, and innovate to solve complex business problems and accelerate the discovery of new ideas.',

    HomeGoogleCloudHeader: 'Google Cloud',
    HomeGoogleCloudSubtitle: 'Technological Service Partner',
    HomeGoogleCloudText01:
      '<strong>Google Cloud</strong> is a suite of cloud computing services that businesses can leverage to run their applications, store their data, and scale their operations.',
    HomeGoogleCloudText02:
      'As a Google Cloud Partner, we provide services such as cloud migration, infrastructure optimization, and custom solution development to help our clients succeed in the cloud.',

    HomeProcessTitle: 'Strict about the outcome. Flexible about the process.',
    HomeProcessText:
      'We develop a workflow and timeline to meet your goals. We can work with different types of deals or arrangements from pay-as-you-go models with short milestones to monthly billable hours. ',
    HomeProcessButton: 'See our process',

    HomeCompaniesTitle: 'Companies that trust us',
    HomeCompaniesButton: 'See the use cases',
    HomeWatchSuccessCasesButton: 'Watch Success Cases',

    HomePrefooterTitle: 'Get <br> started <br> today',
    HomePrefooterText:
      'From streamlining data infrastructure to applying Machine Learning to build custom data products, we can help you leverage your data to drive real value to your business.',
    HomePrefooterButton: 'Request a quote',

    HomeBlogShowcaseTitle: 'Take a look at our blog',
    HomeBlogShowcaseSubtitle: 'The place where data nerds get their daily fix',
    NewsletterSubscriptionTitle:
      'Become a data expert.<br>Subscribe to our newsletter.',
  },
};
