import React, { FC } from 'react';

import Footer from '../components/footer';
import Header from '../components/header';
import { LayoutStyled } from './style';

type Props = {};

const LayoutGeneral: FC<Props> = ({ children }) => {
  return (
    <LayoutStyled>
      <Header />
      <div>{children}</div>
      <Footer />
    </LayoutStyled>
  );
};

export default LayoutGeneral;
