import styled, { css } from 'styled-components';

export const Container = styled.footer<{ isBgWhite: boolean }>`
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isBgWhite }) =>
    isBgWhite
      ? css`
          background-color: transparent;
          color: var(--blue);
        `
      : css`
          background-color: var(--dblue);
          color: var(--white);
        `}
`;

export const Content = styled.div`
  padding: 32px 20px;
  width: 100%;
  max-width: 1087px;
  display: grid;

  grid-template-areas: 'clutch' 'back-to-top' 'contact';
  gap: 64px;

  @media (min-width: 600px) {
    grid-template-areas: 'back-to-top back-to-top' 'clutch contact';
  }

  @media (min-width: 900px) {
    grid-template-areas: 'clutch back-to-top  contact';
  }
`;

export const ClutchWidget = styled.div`
  grid-area: clutch;
  align-self: center;
  justify-self: flex-start;
  background: var(--white);
  box-sizing: border-box;
  width: 226px;
  padding: 20px;
  border-radius: 10px;
`;

export const BackToTopContainer = styled.div`
  grid-area: back-to-top;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const BackToTopButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: inherit;
  transition: color 100ms ease-in-out;
  font-size: 48px;
  height: 48px;
  &:hover {
    color: var(--lblue);
  }
`;

export const BackToTopText = styled.span`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`;

export const AllRightsText = styled.span`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #5975a2;
`;

export const ContactContainer = styled.div`
  grid-area: contact;
  align-self: center;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (min-width: 600px) {
    align-items: flex-start;
  }
`;

export const ContactTitle = styled.h5`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
`;

export const SocialLinksContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 226px;
`;

export const SocialLink = styled.a`
  font-size: 40px;
  @media (min-width: 600px) {
    font-size: 24px;
  }
  color: inherit;
  transition: color 100ms ease-in-out;
  &:hover {
    color: var(--lblue);
  }
`;
