import React, { SVGAttributes } from 'react';

const LinkedInIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="nonzero"
      {...props}
    >
      <path d="M12,0 C5.3735962,0 0,5.3735962 0,12 C0,18.6264038 5.3735962,24 12,24 C18.6264038,24 24,18.6264038 24,12 C24,5.3735962 18.6264038,0 12,0 Z M8.51293945,18.140625 L5.59039308,18.140625 L5.59039308,9.34808348 L8.51293945,9.34808348 L8.51293945,18.140625 Z M7.05175781,8.14746094 L7.03271484,8.14746094 C6.05200195,8.14746094 5.41772461,7.47235106 5.41772461,6.62860106 C5.41772461,5.76580809 6.07141116,5.109375 7.07116697,5.109375 C8.07092288,5.109375 8.68615725,5.76580809 8.70520022,6.62860106 C8.70520022,7.47235106 8.07092288,8.14746094 7.05175781,8.14746094 Z M19.0510254,18.140625 L16.1288452,18.140625 L16.1288452,13.4368286 C16.1288452,12.2546997 15.7056885,11.4484863 14.6482544,11.4484863 C13.8409424,11.4484863 13.3601074,11.9923096 13.1488037,12.5172729 C13.0715332,12.7051392 13.0526733,12.9677124 13.0526733,13.2304688 L13.0526733,18.140625 L10.13031,18.140625 C10.13031,18.140625 10.1685791,10.1729737 10.13031,9.34808348 L13.0526733,9.34808348 L13.0526733,10.5930176 C13.4410401,9.99389648 14.1359253,9.14172366 15.6864624,9.14172366 C17.6092529,9.14172366 19.0510254,10.3983765 19.0510254,13.098999 L19.0510254,18.140625 Z"></path>
    </svg>
  );
};

export default LinkedInIcon;
