export default {
  en: {
    ContactTitle: 'Are you making the best use of your data?',
    ContactText:
      '<p><strong>Mutt Data</strong> is a technology company that helps startups and big companies build and implement <strong>MACHINE LEARNING</strong> solutions that drive real business results.</p><br/><p>Whether you work in finance, insurance, advertising, telcos, on-demand services or  e-commerce, our solutions will help you get ahead of your competition with the latest technologies, techniques and best practices.</p>',
    ContactTextFeatured:
      'Our technology and expertise has the potential to make your life easier. Much easier.',
    ContactFormTitle: 'How can we help you?',
    ContactFormName: 'Your name',
    ContactFormNameError: 'You must have a name…',
    ContactFormCompany: 'Company',
    ContactFormCompanyError: 'Sorry, we need this information too.',
    ContactFormEmail: 'Email',
    ContactFormEmailError: 'Oops! This is not a valid email format.',
    ContactFormMessage: 'Tell us about your project',
    ContactFormMessageError: 'Come on… Don’t be shy.',
    ContactFormDefaultError:
      'Oops! Something went wrong here... <br> Please try again.',
    ContactFormSuccessMsg:
      'Yippee! Your message is already traveling to our server',
    ContactFormButton: 'Submit',
    ContactDropLine: 'Drop us a line:',
  },
};
