import React, { SVGAttributes } from 'react';

const GitHubIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="nonzero"
      {...props}
    >
      <path d="M12,0 C5.37,0 0,5.50948285 0,12.3045117 C0,17.7419956 3.438,22.3530571 8.205,23.9787719 C8.805,24.0945963 9.025,23.7137324 9.025,23.3871286 C9.025,23.0949591 9.015,22.3207098 9.01,21.294986 C5.672,22.0368879 4.968,19.644228 4.968,19.644228 C4.422,18.2240754 3.633,17.844255 3.633,17.844255 C2.546,17.0814838 3.717,17.0971357 3.717,17.0971357 C4.922,17.1826996 5.555,18.3649428 5.555,18.3649428 C6.625,20.246306 8.364,19.702662 9.05,19.3885797 C9.158,18.5924177 9.467,18.0508606 9.81,17.7430391 C7.145,17.4352176 4.344,16.3771465 4.344,11.6627822 C4.344,10.3198457 4.809,9.22212299 5.579,8.3612663 C5.444,8.05031443 5.039,6.7992027 5.684,5.10461933 C5.684,5.10461933 6.689,4.77488513 8.984,6.36616569 C9.944,6.09277847 10.964,5.95712833 11.984,5.95086755 C13.004,5.95712833 14.024,6.09277847 14.984,6.36616569 C17.264,4.77488513 18.269,5.10461933 18.269,5.10461933 C18.914,6.7992027 18.509,8.05031443 18.389,8.3612663 C19.154,9.22212299 19.619,10.3198457 19.619,11.6627822 C19.619,16.389668 16.814,17.4300003 14.144,17.7326045 C14.564,18.1019902 14.954,18.8564137 14.954,20.00944 C14.954,21.6560241 14.939,22.9791347 14.939,23.3787809 C14.939,23.7012109 15.149,24.0862486 15.764,23.96312 C20.565,22.3478398 24,17.7336479 24,12.3045117 C24,5.50948285 18.627,0 12,0 L12,0 Z"></path>
    </svg>
  );
};

export default GitHubIcon;
