import React from 'react';

import DesktopMenu from './DesktopMenu';
import MobileLinks from './MobileItems';

export interface ResourcesMenuProps {
  invertColor?: boolean;
}

const ResourcesMenu = ({ invertColor }: ResourcesMenuProps) => {
  return (
    <>
      <DesktopMenu invertColor={invertColor} />
      <MobileLinks />
    </>
  );
};

export default ResourcesMenu;
