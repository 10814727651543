import React, { Suspense, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import './App.css';
import './i18n';
import LayoutGeneral from './layouts/general';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { rudderanalytics } from './rudderanalytics';

const Homepage = React.lazy(
  () => import(/* webpackChunkName: "Homepage" */ './pages/homepage')
);

const Solutions = React.lazy(
  () => import(/* webpackChunkName: "Solutions" */ './pages/solutions')
);

const UseCases = React.lazy(
  () => import(/* webpackChunkName: "Solutions" */ './pages/usecases')
);

const Company = React.lazy(
  () => import(/* webpackChunkName: "Company" */ './pages/company')
);

const Contact = React.lazy(
  () => import(/* webpackChunkName: "Contact" */ './pages/contact')
);

const PageNotFound = React.lazy(
  () => import(/* webpackChunkName: "404" */ './pages/404')
);

const SubscriptionSuccess = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SubscriptionSuccess" */ './pages/subscription-success/SubscriptionSuccess'
    )
);

const Whitepaper = React.lazy(
  () => import(/* webpackChunkName: "Whitepapers" */ './pages/whitepapers')
);

function App() {
  const location = useLocation();
  useEffect(() => {
    rudderanalytics.page();
  }, [location.pathname]);

  return (
    <Suspense fallback={null}>
      <LayoutGeneral>
        {/* prettier-ignore */}
        <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/solutions" component={Solutions} />
            <Route exact path="/solutions/usecase/:id" component={UseCases} />
            <Route exact path="/company" component={Company} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/subscription-success" component={SubscriptionSuccess} />
            <Route exact path="/whitepapers" component={Whitepaper} />
            <Route exact path="/whitepapers/:whitepaper" component={Whitepaper} />
            <Route path="/muttdata" component={() => {
              window.location.href = 'https://jobs.lever.co/muttdata/';
              return null;
            }} />
            <Route component={PageNotFound} />
          </Switch>
      </LayoutGeneral>
    </Suspense>
  );
}

export default App;
