import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MobileItem } from './style';

const MobileLinks = () => {
  const { pathname } = useLocation();
  return (
    <>
      <MobileItem
        className={pathname.startsWith('/whitepapers') ? 'active' : ''}
      >
        <Link to="/whitepapers">Whitepapers</Link>
      </MobileItem>
      <MobileItem>
        <a
          href="https://landing.muttdata.ai/mutt-data-amazon-rds-delivery"
          target="_blank"
          rel="noopener noreferrer"
        >
          Amazon RDS Specialists
        </a>
      </MobileItem>
    </>
  );
};

export default MobileLinks;
