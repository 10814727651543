import React, { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GitHubIcon from '../icons/GitHubIcon';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import TwitterIcon from '../icons/TwitterIcon';
import UpArrowIcon from '../icons/UpArrowIcon';
import YouTubeIcon from '../icons/YouTubeIcon';
import {
  AllRightsText,
  BackToTopButton,
  BackToTopContainer,
  BackToTopText,
  ClutchWidget,
  ContactContainer,
  ContactTitle,
  Container,
  Content,
  SocialLink,
  SocialLinksContainer,
} from './style';

interface Props {}

enum routePaths {
  HOME = '/',
  SOLUTIONS = '/solutions',
  SOLUTIONS_AUX = '/solutions/',
  SOLUTIONS_USE = '/solutions#usecases',
  SOLUTIONS_PROC = '/solutions#process',
  COMPANY = '/company',
  COMPANY_AUX = '/company/',
  CASE1 = '/solutions/usecase/1-accurately-match-supply-and-demand',
  CASE1_AUX = '/solutions/usecase/1-accurately-match-supply-and-demand/',
  CASE2 = '/solutions/usecase/2-scale-your-business-with-custom-machine-learning',
  CASE2_AUX = '/solutions/usecase/2-scale-your-business-with-custom-machine-learning/',
  CASE3 = '/solutions/usecase/3-unlock-online-marketing-optimization',
  CASE3_AUX = '/solutions/usecase/3-unlock-online-marketing-optimization/',
  CASE4 = '/solutions/usecase/4-automatically-monitor-kpis-with-anomaly-detection',
  CASE4_AUX = '/solutions/usecase/4-automatically-monitor-kpis-with-anomaly-detection/',
  SUBSCRIPTION_SUCCESS = '/subscription-success',
  WHITEPAPERS = '/whitepapers*',
}

const Footer: FC<Props> = () => {
  const { t } = useTranslation();
  let { pathname, hash } = useLocation();

  const isBgWhite = useMemo(() => {
    const route = hash ? `${pathname}${hash}` : pathname;
    return !Object.values(routePaths).some((routePath) => {
      if (routePath === route) return true;
      if (routePath.endsWith('*')) {
        return route.startsWith(routePath.slice(0, -1));
      }
      return false;
    });
  }, [pathname, hash]);

  const handleBackToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container isBgWhite={isBgWhite}>
      <Content>
        <ClutchWidget
          className="clutch-widget"
          data-url="https://widget.clutch.co"
          data-widget-type="2"
          data-height="45"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-clutchcompany-id="2021888"
        />
        <BackToTopContainer>
          <BackToTopButton onClick={handleBackToTop}>
            <UpArrowIcon />
          </BackToTopButton>
          <BackToTopText>Back to the top</BackToTopText>
          <AllRightsText>{t('AllRights')}</AllRightsText>
        </BackToTopContainer>
        <ContactContainer>
          <ContactTitle>Contact</ContactTitle>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.linkedin.com/company/11417586"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </SocialLink>
            <SocialLink
              href="https://twitter.com/mutt_data"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              href="https://github.com/MuttData"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/mutt.data"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/channel/UCzQyAiNmw4os0sTB0v5D4WA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ContactContainer>
      </Content>
    </Container>
  );
};

export default Footer;
