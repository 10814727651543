import React, { useState, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MenuIconClose from '../../images/header/icon-close.svg';
import MenuIconWhite from '../../images/header/icon-menu-white.svg';
import MenuIcon from '../../images/header/icon-menu.svg';
import LogoBlue from '../../images/header/logo-blue.svg';
import LogoWhite from '../../images/header/logo-white.svg';
import { rudderanalytics } from '../../rudderanalytics';
import ResourcesMenu from './ResourcesMenu';
import {
  HeaderStyled,
  Backdrop,
  MenuStyled,
  MenuBar,
  Logo,
  MenuBtn,
  UlStyled,
  QuoteBtn,
  Banner,
  BannerLink,
} from './style';

interface Props {}

const Header: FC<Props> = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuInvertColor, setMenuInvertColor] = useState<boolean>(false);

  let { pathname, key, hash } = useLocation();

  React.useLayoutEffect(() => {
    setMenuOpen(false);
    const elem: HTMLElement | null = document.getElementById(hash.slice(1));

    if (elem) {
      elem.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    if (pathname === '/company' || pathname === '/company/') {
      setMenuInvertColor(true);
    } else {
      setMenuInvertColor(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, key]);

  return (
    <>
      <HeaderStyled invertColor={menuInvertColor} menuIsOpen={menuOpen}>
        <Banner invertColor={menuInvertColor}>
          {t('headerBanner')
            .split('\n')
            .map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          <BannerLink to="/whitepapers/modern-data-stack-technical-roadmap">
            {t('headerBannerLink')}
          </BannerLink>
        </Banner>
        <MenuStyled className="container" menuIsOpen={menuOpen}>
          <MenuBar menuIsOpen={menuOpen}>
            <Logo menuIsOpen={menuOpen}>
              <Link to="/">
                <img src={LogoBlue} alt="logo" />
              </Link>
              <Link to="/">
                <img src={LogoWhite} alt="logo" />
              </Link>
            </Logo>

            <MenuBtn
              menuIsOpen={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <img src={MenuIcon} alt="icon" />
              <img src={MenuIconWhite} className="menu-icon-white" alt="icon" />
              <img src={MenuIconClose} alt="icon" />
            </MenuBtn>
          </MenuBar>
          <UlStyled>
            <li className={pathname === '/' ? 'active' : ''}>
              <Link to="/">Home</Link>
            </li>
            <li className={pathname.startsWith('/solutions') ? 'active' : ''}>
              <Link to="/solutions">Solutions</Link>
            </li>
            <li className={pathname.startsWith('/company') ? 'active' : ''}>
              <Link to="/company">Company</Link>
            </li>
            <ResourcesMenu invertColor={menuInvertColor} />
            <li>
              <a
                href="https://muttdata.ai/blog"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="https://jobs.lever.co/muttdata/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </li>

            <hr />
          </UlStyled>
          <Link
            to="/contact"
            onClick={() =>
              rudderanalytics.track('go_to_contact_page', {
                origin: 'header_contact_button',
              })
            }
          >
            <QuoteBtn>{t('GetQuoteBtn')}</QuoteBtn>
          </Link>
        </MenuStyled>
        <Backdrop menuIsOpen={menuOpen} onClick={() => setMenuOpen(false)} />
      </HeaderStyled>
    </>
  );
};

export default Header;
