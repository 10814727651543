import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Backdrop = styled.div<{ menuIsOpen: boolean }>`
  display: ${(props) => (props.menuIsOpen ? 'block' : 'none')};
  position: fixed;
  z-index: -1;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
`;

export const MenuStyled = styled.div<{ menuIsOpen: boolean }>`
  background: ${(props) => (props.menuIsOpen ? 'var(--dblue)' : 'none')};
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.menuIsOpen ? '100%' : '60px')};
  border-radius: ${(props) => (props.menuIsOpen ? '0 0 30px 30px' : '0')};
  overflow: hidden;
  padding-top: 8px;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border-radius: none;
    padding-bottom: 0;
    overflow: visible;
  }
`;

export const MenuBar = styled.div<{ menuIsOpen: boolean }>`
  box-sizing: border-box;
  background: ${(props) => (props.menuIsOpen ? 'var(--dblue)' : 'none')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 0;
  min-height: 60px;
  @media (min-width: 992px) {
    background: none;
    padding: 0;
    min-height: 40px;
  }
`;

export const Logo = styled.div<{ menuIsOpen: boolean }>`
  a {
    height: 40px;
    width: 181px;
    img {
      object-fit: cover;
      width: 100%;
    }
    &:first-of-type {
      display: ${(props) => (props.menuIsOpen ? 'none' : 'block')};
      @media (min-width: 992px) {
        display: block;
      }
    }
    &:last-of-type {
      display: ${(props) => (props.menuIsOpen ? 'block' : 'none')};
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
`;

export const MenuBtn = styled.div<{ menuIsOpen: boolean }>`
  height: 24px;
  width: 24px;
  cursor: pointer;
  img {
    height: 24px;
    width: 24px;
    &:first-of-type {
      display: ${(props) => (props.menuIsOpen ? 'none' : 'block')};
    }
    &:last-of-type {
      display: ${(props) => (props.menuIsOpen ? 'block' : 'none')};
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const UlStyled = styled.ul`
  display: flex;
  flex-direction: column;
  width: 439px;
  padding: 20px;
  & > li {
    position: relative;
    margin-bottom: 10px;
    a {
      height: 19px;
      width: 70px;
      color: var(--white);
      font-family: Rubik;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 40px;
      @media (min-width: 992px) {
        color: var(--black);
        font-size: 16px;
        line-height: 19px;
      }
    }
    @media (min-width: 992px) {
      margin: 0;
      margin-right: 40px;
      &.active {
        a {
          color: var(--blue);
        }
        &:nth-of-type(1) ~ hr {
          transform: translateX(0px);
        }
        &:nth-of-type(2) ~ hr {
          transform: translateX(85px);
        }
        &:nth-of-type(3) ~ hr {
          transform: translateX(195px);
          background: var(--white) !important;
        }
        &:nth-of-type(4) ~ hr {
          transform: translateX(305px);
        }
      }
      & a:hover {
        color: var(--lblue) !important;
      }
    }
  }
  hr {
    display: none;
    border: none;
    position: absolute;
    height: 2px;
    background: var(--blue);
    bottom: -10px;
    width: 24px;
    transform: translateX(0);
    transition: transform 1s ease;
    @media (min-width: 992px) {
      display: inline;
    }
  }
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
`;

export const QuoteBtn = styled.span`
  box-sizing: border-box;
  display: none;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 119px;
  border: 1px solid var(--blue);
  border-radius: 32px;
  outline: var(--blue);
  cursor: pointer;
  font-family: Rubik;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--blue);
  &:hover {
    background: var(--blue);
    color: var(--white);
  }
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const HeaderStyled = styled.div<{
  invertColor: boolean;
  menuIsOpen: boolean;
}>`
  position: ${(props) => (props.menuIsOpen ? 'fixed' : 'absolute')} !important;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  ${Logo} {
    a {
      &:first-of-type {
        display: ${(props) =>
          props.invertColor
            ? 'none'
            : props.menuIsOpen
            ? 'none'
            : 'block'} !important;
        @media (min-width: 992px) {
          display: ${(props) =>
            props.invertColor ? 'none' : 'block'} !important;
        }
      }
      &:last-of-type {
        display: ${(props) =>
          props.invertColor || props.menuIsOpen ? 'block' : 'none'};
      }
    }
  }
  ${MenuBtn} {
    img {
      &:first-of-type {
        display: ${(props) =>
          props.invertColor || props.menuIsOpen ? 'none' : 'block'};
      }
      &.menu-icon-white {
        display: ${(props) =>
          props.invertColor && !props.menuIsOpen ? 'block' : 'none'};
      }
    }
  }

  ${UlStyled} {
    li {
      a {
        @media (min-width: 992px) {
          color: ${(props) =>
            props.invertColor ? 'var(--white)' : 'var(--blue)'};
        }
      }
      &:before {
        background: ${(props) =>
          props.invertColor ? 'var(--white)' : 'var(--blue)'};
      }
      @media (min-width: 992px) {
        &.active {
          a {
            color: ${(props) =>
              props.invertColor ? 'var(--white)' : 'var(--blue)'};
          }
        }
        & a:hover {
          color: ${(props) =>
            props.invertColor ? 'var(--white)' : 'var(--blue)'};
        }
      }
    }
  }

  ${QuoteBtn} {
    border-color: ${(props) =>
      props.invertColor ? 'var(--white)' : 'var(--blue)'};
    color: ${(props) => (props.invertColor ? 'var(--white)' : 'var(--blue)')};
    &:hover {
      background: ${(props) =>
        props.invertColor ? 'var(--white)' : 'var(--blue)'};
      color: ${(props) => (props.invertColor ? 'var(--blue)' : 'var(--white)')};
    }
  }
`;

export const Banner = styled.div<{ invertColor?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;
  background-color: var(--gray-pill-opacity);
  font-weight: 600;
  gap: 8px;
  ${({ invertColor }) =>
    css`
      color: var(${invertColor ? `--white` : `--black`});
    `};
`;

export const BannerLink = styled(Link)`
  font-weight: 700;
  color: inherit;
  text-decoration: underline;
`;
