export default {
  en: {
    SolutionMainTitle: 'A bulletproof machine learning process',
    SolutionMainSubtitle:
      'We help organizations understand what is economically possible for them and identify the opportunities where data products can <strong>add the most value.</strong>',

    SolutionProcessTitle: 'We adapt to your business needs',
    SolutionProcessSubtitle: 'Our process',
    SolutionProcessFlow1Title: 'Business Understanding',
    SolutionProcessFlow1Text:
      'We identify opportunities to optimize and scale your business results. We assess your team’s skills, tools and technology to determine the company’s data maturity.',
    SolutionProcessFlow2Title: 'Scoping & Plan',
    SolutionProcessFlow2Text:
      'We provide ideation sessions to define which issues to tackle and product design workshops to determine the best course of action. We apply industry best practices like Google’s Rules of ML and the Machine Learning Canvas.',
    SolutionProcessFlow3Title: 'Data Preparation',
    SolutionProcessFlow3Text:
      'We construct a solid foundation with modern data systems to build upon using Data Engineering best practices.',
    SolutionProcessFlow4Title: 'Custom Solution',
    SolutionProcessFlow4Text:
      'We build, test and iterate the system to ensure it efficiently achieves your goals.',
    SolutionProcessFlow5Title: 'Deployment',
    SolutionProcessFlow5Text:
      'We seamlessly integrate the custom data products within your infrastructure, syncing with your team to ensure sustainability. We make sure your team has the know-how and tools to successfully manage the solution.',
    SolutionProcessFlow6Title: 'Optimization',
    SolutionProcessFlow6Text:
      'We help you automate the full lifecycle of your machine learning systems to properly monitor and evolve them. We validate the resulting model using both offline and online methods.',

    SolutionProcessWheelUnderstanding: 'Understanding',
    SolutionProcessWheelDeployment: 'Deployment',
    SolutionProcessWheelModeling: 'Modeling',

    SolutionArtToolsTitle: 'State of the <br> art tools',
    SolutionToolCard1Title: 'Delta Lake',
    SolutionToolCard2Title: 'Apache Spark',
    SolutionToolCard3Title: 'Apache Airflow',
    SolutionToolCard4Title: 'Databricks',
    SolutionToolCard5Title: 'MLflow',
    SolutionToolCard6Title: 'Facebook Prophet',
    SolutionToolCard7Title: 'PyTorch',
    SolutionToolCard8Title: 'Scikit Learn',

    SolutionUseCasesTitle: 'Use cases',
    SolutionUseCasesCard1Title: 'Accurately match supply and demand',
    SolutionUseCasesCard2Title:
      'Scale your business with custom Machine Learning',
    SolutionUseCasesCard3Title: 'Unlock online marketing optimization',
    SolutionUseCasesCard4Title:
      'Automatically monitor KPIs with Anomaly Detection',
    SolutionUseCasesCardBtn: 'Learn more',

    SolutionPrefooterTitle: 'Let’s talk <br> about your <br> project',
    SolutionPrefooterText:
      'From streamlining data infrastructure to custom products, we can help your team leverage your data to drive real value to your business.',
    SolutionPrefooterButton: 'Contact us',
  },
};
