import styled, { css } from 'styled-components';

export const Container = styled.li<{ invertColor?: boolean }>`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
  position: relative;
  ${({ invertColor }) =>
    invertColor
      ? css`
          --font-color: var(--white);
          --menu-background-color: rgb(255, 255, 255, 0.1);
        `
      : css`
          --font-color: var(--blue);
          --menu-background-color: var(--white);
        `}
`;

export const OpenButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  font-family: Rubik;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
  cursor: pointer;
  &:hover {
    color: var(--lblue);
  }
`;

export const Backdrop = styled.div<{ open?: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Menu = styled.ul<{ open?: boolean }>`
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 100;
  background-color: var(--menu-background-color);
  box-shadow: var(--shadow);
  border-radius: 8px;
  overflow: hidden;
  transition: opacity 200ms;
  ${({ open }) =>
    open
      ? css`
          pointer-events: all;
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `}
`;

export const Item = styled.li`
  padding: 12px 16px;
  font-family: Rubik;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: var(--lblue);
  }
`;
