import React, { SVGAttributes } from 'react';

const UpArrowIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      fillRule="evenodd"
      strokeWidth="0"
      viewBox="0 0 74 74"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M74 37C74 57.4345 57.4345 74 37 74C16.5655 74 0 57.4345 0 37C0 16.5655 16.5655 0 37 0C57.4345 0 74 16.5655 74 37Z M37 25C38.5031 25 39.9406 25.6151 40.9785 26.7024L51.4785 37.7024C53.5758 39.8996 53.4949 43.3811 51.2976 45.4784C49.1004 47.5758 45.6189 47.4948 43.5216 45.2976L37 38.4655L30.4785 45.2976C28.3811 47.4948 24.8996 47.5758 22.7024 45.4784C20.5052 43.3811 20.4242 39.8996 22.5216 37.7024L33.0216 26.7024C34.0594 25.6151 35.497 25 37 25Z" />
    </svg>
  );
};

export default UpArrowIcon;
