export default {
  en: {
    Whitepaper1Title: 'Meet The Modern Data Stack',
    Whitepaper1Text1:
      'The Modern Data space has evolved by leaps and bounds, altering the very fabric of business analytics and data management.',
    Whitepaper1TextBlue1:
      'The purpose of this stack, which centers around an ecosystem of tools, is to support the collection, movement, storage, transformation, and operationalization of data.',
    Whitepaper1Text2: 'In this document, you’ll learn: ',
    Whitepaper1TextBlue2: `What is a Modern Data Stack?<br/>Questions to ask before implementing a MDS<br/>Why Implement a Modern Data Stack?<br/>Its Game-Changing Benefits`,

    Whitepaper2Title: 'The Modern Data Stack:<br/>A Technical Roadmap',
    Whitepaper2Text1:
      'The key to building a successful Modern Data Stack lies in knowing when to expand, in which way, and what challenges are going to appear along the way.',
    Whitepaper2TextBlue1:
      "The Modern Data Stack is made up of multiple components. Much like a band or orchestra, a single instrument can carry a tune, but it's not until you layer in all the other instruments (or components) that you access the full experience.",
    Whitepaper2Text2: 'In this document, you’ll learn: ',
    Whitepaper2TextBlue2: `[Use Case] What challenges should you expect when implementing your MDS?<br/>What components make up a Modern Data Stack?<br/>What tools do we recommend for each component?<br/>What Can Mutt Data Do For Your Data Stack?`,

    FormTitle: `Download our whitepaper`,
  },
};
