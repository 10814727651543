import * as rudderSdk from 'rudder-sdk-js';

let rudderanalytics = rudderSdk;

const { NODE_ENV } = process.env;

function mockFn<Res = unknown>(res?: Res) {
  return function () {
    return res as Res;
  };
}

if (NODE_ENV === 'development') {
  const methodsToMock: Partial<typeof rudderanalytics> = {
    alias: mockFn(),
    identify: mockFn(),
    load: mockFn(),
    page: mockFn(),
    reset: mockFn(),
    track: mockFn(),
    ready: mockFn(),
    getAnonymousId: mockFn('fake-anonymous-id'),
    endSession: mockFn(),
    getSessionId: mockFn(1),
    getGroupId: mockFn('fake-group-id'),
    getGroupTraits: mockFn({}),
    getUserId: mockFn('fake-user-id'),
    getUserTraits: mockFn({}),
    group: mockFn(),
    setAnonymousId: mockFn(),
    startSession: mockFn(),
  };
  rudderanalytics = Object.assign({ ...rudderanalytics }, methodsToMock);
}

const DATA_PLANE_URL = 'https://muttdatapdlvj.dataplane.rudderstack.com';
const WRITE_KEY = '2Qn0tJDIqPMFCUkiZ0Ms4Rid2XN';

rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL);

export { rudderanalytics };
