import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// PAGES
import Common from './components/i18n';
import PageNotFound from './pages/404/i18n';
import Company from './pages/company/i18n';
import Contact from './pages/contact/i18n';
import Homepage from './pages/homepage/i18n';
import Solutions from './pages/solutions/i18n';
import SubscriptionSuccess from './pages/subscription-success/i18n';
import UseCases from './pages/usecases/i18n';
import Whitepapers from './pages/whitepapers/i18n';

const resources = {
  en: {
    translation: {
      ...Common.en,
      ...Homepage.en,
      ...Solutions.en,
      ...UseCases.en,
      ...Company.en,
      ...Contact.en,
      ...PageNotFound.en,
      ...SubscriptionSuccess.en,
      ...Whitepapers.en,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
