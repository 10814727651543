import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Backdrop, Container, Item, Menu, OpenButton } from './style';

export interface DesktopMenuProps {
  invertColor?: boolean;
}

const DesktopMenu = ({ invertColor }: DesktopMenuProps) => {
  let { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  return (
    <Container
      invertColor={invertColor}
      className={pathname.startsWith('/whitepapers') ? 'active' : ''}
    >
      <OpenButton onClick={handleOpen}>Resources</OpenButton>
      <Backdrop open={open} onClick={handleClose} />
      <Menu open={open} onClick={handleClose}>
        <Item>
          <Link to="/whitepapers">Whitepapers</Link>
        </Item>
        <Item>
          <a
            href="https://landing.muttdata.ai/mutt-data-amazon-rds-delivery"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon RDS Specialists
          </a>
        </Item>
      </Menu>
    </Container>
  );
};

export default DesktopMenu;
