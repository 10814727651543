export default {
  en: {
    UseCaseMainPreTitle: 'Use case',

    UseCaseMainTitle1: 'Accurately match supply and demand',
    UseCaseMainSubtitle1:
      'Use historical operational data and contextual information to <strong>accurately forecast demand</strong>, and efficiently assign resources from work schedules to production capacity.',
    UseCaseMainCardText11:
      'Apply modern Machine Learning techniques to <strong>get the most accurate forecasts.</strong>',
    UseCaseMainCardText12:
      '<strong>Automate the process</strong> using modern data engineering tools.',
    UseCaseMainCardText13:
      'Limit excess supply and <strong>minimize costs.</strong>',

    UseCaseMainTitle2: 'Scale your business with custom Machine Learning',
    UseCaseMainSubtitle2:
      'Using Machine Learning to <strong>power and optimize</strong> your company’s processes involves much more than just training a model and doing some predictions.',
    UseCaseMainCardText21:
      'Quickly <strong>scale up development capabilities</strong> throughout the entire process.',
    UseCaseMainCardText22:
      'Implement best practices for <strong>Amazon Web Services</strong> to ensure long-term evolutionary capabilities.',
    UseCaseMainCardText23:
      "Learn from <strong>our team's expertise</strong> to maintain, operate and eventually evolve your systems. ",

    UseCaseMainTitle3: 'Unlock online marketing optimization',
    UseCaseMainSubtitle3:
      '<strong>Connect millions of different data points</strong> across multiple marketing campaigns in one dashboard to effortlessly optimize for ROAS, retention rates and overall customer LTV.',
    UseCaseMainCardText31:
      'Remove the guesswork and consistently <strong>increase ROAS</strong> from digital campaigns.',
    UseCaseMainCardText32:
      'Help analysts <strong>focus on the overall strategy,</strong> rather than wasting time on manual optimizations.',
    UseCaseMainCardText33:
      '<strong>Reduce the overall budget</strong> and obtain the same results with cross-channel optimization.',

    UseCaseMainTitle4: 'Automatically monitor KPIs with Anomaly Detection',
    UseCaseMainSubtitle4:
      'Surface relevant changes and digested updates to <strong>make quick, informed decisions.</strong>',
    UseCaseMainCardText41:
      '<strong>Understand</strong> the usual performance of each KPI.',
    UseCaseMainCardText42:
      'Automatically <strong>identify unusual behavior</strong> and root cause.',
    UseCaseMainCardText43:
      'Send <strong>automated reports</strong> to the right people.',

    UseCaseMainNextUseCase: 'See next use case',
    UseCaseMainNextUseCaseMobile: 'See next',

    UseCaseChallengeChallengeTitle: 'Challenge',
    UseCaseChallengeSolutionTitle: 'Solution',

    UseCaseChallengeHeadline1:
      'Forecasting and supply optimization for B2B tech startup.',
    UseCaseChallengeChallenge1:
      'A B2B startup in the food delivery industry needed to <strong>find a way to efficiently assign schedules</strong> to the delivery persons, and reduce costs.',
    UseCaseChallengeSolution1:
      'We built a robust Machine Learning system that uses historical operational data and other data sources (like weather data) to <strong>accurately forecast real-time demand.</strong> This allowed the client to minimize the number of people available in each area while maintaining their Service Level Agreements (SLA).',

    UseCaseChallengeHeadline2:
      'Real-time Machine Learning that powers thousands of predictions per second.',
    UseCaseChallengeChallenge2:
      'A company in the <strong>Real Time Bidding (RTB)</strong> Ads space needed to implement a system that would efficiently price each ad auction while processing thousands of auctions every second with response times under 20 milliseconds.',
    UseCaseChallengeSolution2:
      "Aside from the statistical and mathematical complexities of designing such a system, making it economically viable requires a combination of deep skill in <strong>Software Engineering, Cloud Infrastructure and business know-how.</strong> <br><br>Our team started by evaluating the client's situation, analyzing both the technical aspects and how this impacted the company’s financial status. <br><br>We then advanced in several fronts by making the infrastructure more stable and efficient, devising a new pricing model to simplify the optimization process and developing a Machine Learning system that combined several real-time estimations to accurately price the auctions.",

    UseCaseChallengeHeadline3: 'Online marketing optimization for e-commerce.',
    UseCaseChallengeChallenge3:
      'As one of the <strong>largest e-commerce marketplaces in LATAM</strong>, our client is one of the biggest spenders in Google SSC, DSA and Adwords.<br><br>They have multiple teams of marketing analysts that plan, create and operate campaigns all over LATAM.<br><br>Each team is composed of several analysts, who individually operate a subset of campaigns relying solely on their personal past experience. The lack of cross-team best practices and strategies leads to suboptimal local solutions.',
    UseCaseChallengeSolution3:
      'We worked together with the Marketing Science team in tackling these operational complexities. <br><br>The solution was to develop a <strong>fully automated Machine Learning system</strong> that explored and exploited all the available data to achieve robust optimization across all campaigns.<br><br>Daily predictions, constraints set by the marketing team (budget, price variation, etc) and cross-campaign information are used  to suggest several possible budget and campaign configurations optimized to deliver more revenue for their business.<br><br>Additionally, we implemented scheduled updates to facilitate decision-making and ensure everyone was kept in the loop.',

    UseCaseChallengeHeadline4: 'Anomaly Detection for large telecom provider.',
    UseCaseChallengeChallenge4:
      'The company had developed and purchased a myriad of systems to meet their reporting needs.<br><br>Multiple teams had to <strong>sort through several different systems</strong> and interpret the information to find the relevant anomalies.<br><br>They then manually compiled reports to email to the management team.',
    UseCaseChallengeSolution4:
      'We built a Machine Learning system that <strong>understands the usual behavior of each KPI at each dimension</strong> (country, province, city, hourly, daily, etc) and the correlation with other KPIs. <br><br>Internal datasets like promotions, price changes, new system versions were used as contextual information to explain the anomalies.<br><br><strong>Automated</strong> reports with summaries were set up through email, chat, and other channels to alert the relevant people and give additional in-depth information through custom dashboards.',

    UseCaseResultsTitle: 'Results',
    UseCaseResultsText1:
      'We achieved <strong>40%</strong> better predictions and a <strong>70%</strong> reduction of excess capacity.',
    UseCaseResultsText2:
      'As a result of our partnership, the client saw a significant reduction of infrastructure costs, and an increase in the margin from the ad campaigns.<br><br>In turn, this allowed our client to offer a better service to their own customers: advertisers that could now <strong>run highly efficient ad campaigns</strong> on our client’s platform. ',
    UseCaseResultsText3:
      'The developed system helps account managers make informed campaign decisions to efficiently assign budgets of hundreds of thousands of dollars across hundreds of different campaigns. <br><br>By implementing this solution, the client was able to <strong>reduce costs by over 10%</strong>, while maintaining their target ROI and GMV, and even increasing <span>GMV</span> by more than 10% in specific campaigns.',
    UseCaseResultsText4:
      'The system successfully removed the manual effort of sorting through the data to identify root causes and reduced the time it took to identify relevant issues from days to mere minutes. <br><br><strong>The 8X faster anomaly detection</strong> enabled faster issue resolution, enhanced the service reliability, and allowed an overall better customer experience.',

    UseCaseOtherCasesTitle: 'See other use cases',

    UseCasePrefooterTitle: 'Let’s talk <br> about your <br> project',
    UseCasePrefooterText:
      'From streamlining data infrastructure to custom products, we can help your team leverage your data to drive real value to your business.',
    UseCasePrefooterButton: 'Contact us',
  },
};
