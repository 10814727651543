import React, { SVGAttributes } from 'react';

const YouTubeIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="nonzero"
      {...props}
    >
      <path d="M10.50531,14.248169 L14.4087525,12 L10.50531,9.75183103 L10.50531,14.248169 Z M12,0 C5.3735962,0 0,5.3735962 0,12 C0,18.6264038 5.3735962,24 12,24 C18.6264038,24 24,18.6264038 24,12 C24,5.3735962 18.6264038,0 12,0 Z M19.4982334,12.0122681 C19.4982334,12.0122681 19.4982334,14.4459229 19.1894531,15.6194458 C19.0164185,16.2617798 18.5099487,16.7682495 17.8676147,16.9411011 C16.6940918,17.25 12,17.25 12,17.25 C12,17.25 7.31817628,17.25 6.13238527,16.928833 C5.49005128,16.7559815 4.98358153,16.2493286 4.81054688,15.6069946 C4.50164794,14.4459229 4.50164794,12 4.50164794,12 C4.50164794,12 4.50164794,9.56652834 4.81054688,8.39300536 C4.98339844,7.75067138 5.50231931,7.2317505 6.13238527,7.05889894 C7.3059082,6.75 12,6.75 12,6.75 C12,6.75 16.6940918,6.75 17.8676147,7.07116697 C18.5099487,7.24401853 19.0164185,7.75067138 19.1894531,8.39300536 C19.5106201,9.56652834 19.4982334,12.0122681 19.4982334,12.0122681 L19.4982334,12.0122681 Z"></path>
    </svg>
  );
};

export default YouTubeIcon;
